// Note this has a twin in control-center-backend.
export enum ProductKey {
    SmartRadio = 'smart-radio',
    WorkforceDiagnostics = 'workforce-diagnostics',
    EquipmentDiagnostics = 'equipment-diagnostics',
    Protect = 'protect',
    DigitalConfinedSpaceManagement = 'digital-confined-space-management',
    Hotspot = 'hotspot',
}

export interface Product {
    id: number;
    name: string;
    key: ProductKey;
    features: string[];
}

export interface ProductFeature {
    id: number;
    name: string;
    key: string;
}
